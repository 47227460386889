import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Feature, PageLayout } from '../../components'

const Partners = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {in: [
        "00-feature-partners.png",
        "00-hero-partners.png"
      ]}}) {
        images: edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const fluidImage = (path) => {
    const images = data.allFile.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }

  return (
    <PageLayout 
      location={location}
      title="Partners"
      titleTemplate="Hippobyte"
      description="Helping enterprises unlock and deliver financial and business analytics – faster and easier than ever before."
      backgroundColor="#fff"
      pageTheme="dark"
      footer={true}
      pageHero={{
        section: {
          margin: { horizontal: "11vw" }
        },
        main: {
          heading: {
            label: "Solve more of your customer's problems with Hippobyte",
          },
          subHeading: {
            label: "Whether you are a technology or business consultant — or you’re responsible for your clients’ reporting and analytics solutions — we would love to support your work. Join us in helping build data-driven organizations.",
          },
          style: { flex: "0 1 45%" },
        },
        aside: {
          content: (
            <Img fluid={fluidImage("00-hero-partners.png")} />
          ),
          style: { flex: "0 1 55%" }
        }
      }}
    >
    <Feature 
      themeColor="#f6f9fb"
      reverse
      image={{
        type: "fluid",
        content: fluidImage("00-feature-partners.png"),
        width: "500px",
        style: { bottom: "8%", right: "8%" }
      }}
      aside={{
        title: "Establish new revenue streams & modernize your clients’ analytics.",
        description: "If you are a consultant, transformation leader, or managed service provider, we will supply resources to help you value to your clients while increasing your revenue. We offer a simple, secure and reliable data integration platform, partner-specific pricing, and access to technology experts."
      }}
    />
    </PageLayout>
  )
}

export default Partners